import request from '@/utils/request'

export const allocation = (data,scope) => {
    return request({
        url: '/api/backend/allocation',
        method: 'post',
        data: {
            records: data,
            scope: scope
        }
    })
}

export const list = (listQuery) => {
    return request({
        url: '/api/backend/allocation/list',
        method: 'get',
        params: listQuery
    })
}

export const getScope = () => {
    return request({
        url: '/api/backend/allocation/getScope',
        method: 'get',
    })
}

export const saveScope = (allocation_scope) => {
    return request({
        url: '/api/backend/saveScope',
        method: 'post',
        data: {allocation_scope: allocation_scope}
    })
}


export const download = (id) => {
    return request({
        url: '/api/backend/download',
        method: 'get',
        params: {allocation_record_id: id}
    })
}

export const upload = (params) => {
    return request({
        url: '/api/backend/allocation/upload',
        method: 'post',
        data: params,
    })
}
